import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

//import perk1Img from "../images/speed.svg"
//import perk2Img from "../images/piggy-bank.svg"
import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"

export default () => (
  <>
    <Layout>
      <Banner />
      <TextBlock
        id="about"
        title="IT ei välttämättä ole aina ihan helppoa mutta onneksi sitä varten on LevelSafety"
        paragraph="Olen työskennellyt IT alalla eri tehtivissä vuosia! Aloittaen lähituessa josta työn kautta opiskelu palvelin infran järjestelmäasiantuntijaksi. 
        Kuitenkin mielenkiinto on aina ollut myös ohjelmistojen kehityksessä joten alku taipaleen jälkeen opiskelin itseni koodariksi. Siitä lähtien olenkin ollut 
        IT-alan sekatyömies. Tarvittaessa fullstack koodari, infra asiantuntija, devops heppu jne."
      >
        <Link to="perks" smooth={true} duration={500}>
          <Button label="Tell Me More" cta="Kerro lisää!" />
        </Link>
      </TextBlock>
      <TextBlockImg
        id="perks"
        title="Kokemusta tekemiseen"
        subtitle="Minulla on yli 10 vuoden kokemus IT alan eri kulmilta ja aina olen innokas opettelemaan jotain uutta.
        Tässä osa-alueita joilla pystyn auttamaan."
      >
        <div className="content-container">
          <Perk
            img={perk3Img}
            alt="Pilvipalvelut"
            title="Cloud"
            content="Nykypäivänä harvoin kannattaa itse hankkia omat palvelimet, 
            monesti ei välttämättä kannata hankkia edes virtuaali palvelimia koska nykypäivän pilviteknologiat tarjoavat kustannustehokkaita ja helposti ylläpidettäviä ratkaisuja. 
            Esimerksiksi AWS tai Google Cloud.
            Jos olet miettinyt esimerkiksi kustannuksien optimointia tai pilven reunalle siirtymistä mutta osaaminen puuttuu niin otahan yhteyttä."
          />
          <Perk
            img={perk3Img}
            alt="DevOps"
            title="Prosesseihin DevOpsia?"
            content="Onko sovelluspäivitykset asiakkaille aina käsityötä? Ajetaanko automaatio testit ainoastaa käsin? Tarvitseeko CI/CD putkisto kehittämistä? Onko palvelinympäristö versiohallinnassa? Pitäisikö kehittäjienkin pystyä päivittämään palvelimille sovelluksiaan? Jos vastasit johonkin kyllä niin otahan yhteyttä."
          />
          <Perk
            img={perk3Img}
            alt="Homepages"
            title="Kotisivut"
            content="Moni yritys hyötyisi nykyaikaista kotisivuista. Niiden avulla voidaan parantaa mm. saavutettavuutta, myyntiä, näkyvyyttä, asiakaskokemusta jne.
            Monesti vaikka on kotisivut niiden ylläpito maksaa maltaita tai niiden päivittäminen on kallista/hidasta. 
            Ota yhteyttä jos haluat että tehdään kotisivusi nykypäivän tekniikoilla ja että ne toimivat NOPEASTI. 
            Esimerkiksi hakukoneet nykyään arvostavat sisällön lisäksi sitä että sivut latautuvat nopeasti ja ovat käytettäviä myös mobiililla."
          />
          <Perk
            img={perk3Img}
            alt="Design"
            title="Arkkitehtuuri"
            content="Onko mielessä hyvä idea mutta vielä hieman hakusessa miten ja millä teknologioilla lähtisi sitä toteuttamaan? TAI nykyinen
            tuote kaipaa pientä päivitystä koska se ei enää skaalaudu nykyisiin asiakamäärin tai on raskas ylläpitää. "
          />
        </div>
      </TextBlockImg>
      <Packages
        title="Mitä se maksaa?"
        para="Arvostan läpinäkyvyyttä kaikessa toiminnassani joten tässä muutamia esimerkki tuntihintoja. Kaikesta voi toki aina neuvotella."
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Projekti" id="packages">
            Määritellyn mittainen projekti joko tunti hinnalla tai määritellyllä kokonais hinnalla.
            <ul>
              <li>
                <MdDone />
                Aloitus palaveri ilmaiseksi (1h)
              </li>
              <li>
                <MdDone />
                Senior tason osaaminen
              </li>
              <li>
                <MdDone />
                Joustava palvelu
              </li>
              <li>
                <MdDone />
                Jatko sopimus mahdollinen
              </li>
              <li>
                <MdDone />
                Hinta sop. mukaan.
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="Contact" cta="Ota yhteyttä!" />
            </Link>
          </Package>
          <Package title="Tuntilaskutus 1" active={true}>
            Pystyn tekemään työt valitsemallani alustalla jolloin pystyn myös olemaan tehokkain (linux).
            <ul>
            <li>
                <MdDone />
                Aloitus palaveri ilmaiseksi (1h)
              </li>
              <li>
                <MdDone />
                Senior tason osaaminen
              </li>
              <li>
                <MdDone />
                Joustava palvelu
              </li>
              <li>
                <MdDone />
                72e/h + alv 24%
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="Contact" cta="Ota yhteyttä!" />
            </Link>
          </Package>
          <Package title="Tuntilaskutus 2">
            Työt tehdään ainoastaan windows alustalla johtuen valitusta teknologiasta tai muusta.
            <ul>
            <li>
                <MdDone />
                Aloitus palaveri ilmaiseksi (1h)
              </li>
              <li>
                <MdDone />
                Senior tason osaaminen
              </li>
              <li>
                <MdDone />
                Joustava palvelu
              </li>
              <li>
                <MdDone />
                80e/h + alv 24%
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="Contact" cta="Ota yhteyttä!" />
            </Link>
          </Package>

        </IconContext.Provider>
      </Packages>
      <Contact
        id="contact"
        title="Ota yhteyttä"
        subtitle="Jätä viesti alla olevalla lomakkeella tai lähetä sähköpostia mikko(at)levelsafety.fi tai soita p. +358445388641 niin palaan asiaan mahdollisiman pian!"
      />
    </Layout>
  </>
)
